import { emailFormat, requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import CreateOnBoardingInviteFriendCommand
  from '@/modules/home-dashboard/invite-friend/application/commands/create-on-boarding-invite-friend-command';
import { GetRecaptchaQuery } from '@/modules/recaptcha/application/queries';

// Domain
import { InviteFriendDto } from '@/modules/home-dashboard/invite-friend/domain/dtos/invite-friend-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InviteFriendModalViewModel {
  @Inject(TYPES.CREATE_ON_BOARDING_INVITE_FRIEND_COMMAND)
  private readonly create_invite_friend_command!: CreateOnBoardingInviteFriendCommand;

  @Inject(TYPES.GET_RECAPTCHA_QUERY)
  private readonly get_recaptcha_query!: GetRecaptchaQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  i18n_namespace = 'components.home-dashboard.invite_friend_modal';

  inputs = {
    email: '',
  };

  rules = {
    email: [requiredRule, emailFormat],
  };

  valid_form = false;

  is_successful = false;

  is_error = false;

  is_loading = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  inviteFriend= async () => {
    try {
      this.is_loading = true;

      const recaptcha = await this.get_recaptcha_query.execute();

      const invite_friend_dto: InviteFriendDto = {
        referred_email: this.inputs.email,
        captcha: recaptcha.token,
      };

      await this.create_invite_friend_command.execute(invite_friend_dto);
      this.is_successful = true;
      return true;
    } catch {
      this.is_error = true;
      return false;
    } finally {
      this.is_loading = false;
    }
  }

  reset = () => {
    this.inputs.email = '';
  }

  convertToLowerCase= () => {
    this.inputs.email = this.inputs.email.toLowerCase();
  }
}
